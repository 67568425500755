// Colors
$east-bay: #3e3d6b;
$scampi: #6766a8;
$logan: #a8a7cd;
$gallery: #efefef;
$tundora: #403c3c;
$bajra-primary: #be273a;
$bajra-primary-lt: #f7e0e2;
$bajra-primary-xlt: #fdf9fa;
$ai-logo-grey-lt: rgba(#b2b2b2, 0.8);
$ai-logo-grey-dk: #6d6d6d;
$grey-n: rgba(black, 0.8);
$grey-md: rgba(black, 0.5);
$grey-xlt: #dddbdd;

// Theme
$app-primary-color: $bajra-primary;
$app-primary-color-lt: $bajra-primary-lt;
$app-primary-color-xlt: $bajra-primary-xlt;
$app-secondary-color: $scampi;

$border-color: $grey-xlt;
$txt-n: $grey-n;
$txt-md: $grey-md;

$success-color: #4caf50;
$error-color: #f44336;
$warning-color: #ff9800;

//Fonts
$font-family: "Oxanium", sans-serif;
