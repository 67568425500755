/* FOR SCREEN SIZES */
// Breakpoints
$bp-xsmall: 30em; //480px
$bp-small: 36em; // 576px
$bp-medium: 48em; // 768px
$bp-large: 64em; // 1024px
$bp-xlarge: 85em; // 1360px
$bp-xxlarge: 120em; // 1920px
$bp-xxxlarge: 160em; // 2560px

// Media Queries
$maxq-xsmall: "(max-width: #{$bp-xsmall})";
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$maxq-medium: "(max-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$maxq-large: "(max-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-xxxlarge: "(min-width: #{$bp-xxxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
