
.btn-app-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: #3e3d6b;
  --bs-btn-border-color: #3e3d6b;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6766A8;
  --bs-btn-hover-border-color: #6766A8;
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color(#3e3d6b, 20%)};
  --bs-btn-active-border-color: #{shade-color(#3e3d6b, 20%)};
  --bs-btn-disabled-bg: #A8A7CD;
  --bs-btn-disabled-border-color: #A8A7CD;
}
