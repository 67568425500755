@import "../sass-utils/variables";

.mat-mdc-form-field-flex,
.mat-mdc-form-field-focus-overlay,
.mat-mdc-text-field-wrapper {
  background-color: white !important;
}

// content inside the mat-menu in profile mat menu

.setting-menu {
  width: 220px;
  border-radius: 16px;

  .profile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .profile-image {
      height: 50px !important;
      width: 50px !important;
      line-height: 50px !important;
      font-size: 50px !important;
    }

    .full-name {
      font-size: 15px;
      font-weight: 500;
    }

    .email {
      font-size: 12px;
    }

    .company {
      background-color: $gallery;
      font-size: 12px;
      padding: 1px 4px;
      border-radius: 12px;
      margin: 12px 0;
    }

    button {
      justify-content: center;

      mat-icon {
        height: 18px !important;
        width: 18px !important;
        line-height: 18px !important;
        font-size: 18px !important;
      }

      span {
        font-size: 15px;
      }
    }
  }
}

.mat-typography,
.mat-body,
.mat-h1,
.mat-body-2,
.mat-headline-5 {
  font-family: $font-family !important;
}
