/* You can add global styles to this file, and also import other style files */

@import "app/shared/scss/sass-utils/variables";
@import "app/shared/scss/base/reset";
@import "app/shared/scss/sass-utils/breakpoints";
@import "app/shared/scss/sass-utils/functions";
@import "app/shared/scss/sass-utils/helpers";
@import "app/shared/scss/sass-utils/mixins";
@import "app/shared/scss/vendors/bootstrap";
@import "app/shared/scss/vendors-extensions/angular-material-extension";
@import "app/shared/scss/vendors-extensions/boostrap-extension";
